.section-padding {
	padding: 30px 0;

}

.brand-carousel {
	margin-top: 50px;

	


}

.brand-carousel .box {
	height: 15vh;
	filter: grayscale(100);

	margin-top: 15px;
	margin-bottom: 15px;
}



.owl-dot.active {
	background-color: rgb(243, 243, 248) !important;
}

.box img {
	float: left;
	object-fit: contain;
	width: 15vh;
	height: 15vh;
}

.small img{
	padding: 30px;

}

.mid img{
	padding: 30px;

}

.big img{
	padding: 30px;

}
.bigger img{
	padding: -10px;
	
}



#loading-screen {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 1;
	transition: 1s opacity;
}

#loading-screen.fade-out {
	opacity: 0;
}

#loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #ff0000;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #40ff00;
	-webkit-animation: spin 3s linear infinite;
	animation: spin 3s linear infinite;
}

#loader:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #0540f1;
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}